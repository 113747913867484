.app-wrapper { position: relative; min-height: 100vh; padding: 180px 0 100px;
  .illustration-page & {padding: 0;}
}

.inner-container { width: 80%; margin: 0 auto;
  &.page__header {height: auto;}
}

// Menu caché qui apparait que quand il prend le focus avec Tab pour RGAA
.hidden-menu { position: absolute; top: 0; left: 50%; transform: translateX(-50%); z-index: 2000; background-color: $primary; padding: 5px 50px; transform: translateY(-100%);
  a { text-decoration: none; color: white; }
  &:focus-within { transform: translateY(0); }
}

@media screen and (max-width: $small-screen) {
  .inner-container { width: 90%;}
  .app-wrapper { padding-top: 50px !important;}
}

.left-menu { position: absolute; right: 0; top: 120px; z-index: 10;
  &__container { text-align: right;}
  &__item { display: block; margin-bottom: 10px;}
  &__link { display: inline-block; padding: 0 60px 0 25px; height: 70px; line-height: 70px;
            background-color: white;
            border:1px solid $border-color;
            text-decoration: none;
            transition: all .3s linear;
    i { display: inline-block; line-height: 1; vertical-align: middle; opacity: 1; transition: opacity .3s linear;}
    span { display: inline-block; width: 0; max-width: 80px; overflow: hidden;
           font-size: 12px; font-family: 'Montserrat', sans-serif; font-weight:700; line-height: 1; color:white; vertical-align: middle; text-align: left;
           opacity: 0;
           transition: opacity .3s linear;}
    &--calc {
      i { width: 28px; height: 32px;
          background: transparent url('../../images/front/picto-calc.svg') center no-repeat; background-size: contain;
        }
    }
    &--contact {
      i { width: 28px; height: 19px;
          background: transparent url('../../images/front/picto-contact.svg') center no-repeat; background-size: contain;
        }
    }
    &:hover, &:focus-within { background-color: $primary; border-color:$primary;
      i { opacity: 0; width: 0;}
      span { opacity: 1; width: auto; transition: none;}
    }
  }
}


@media screen and (max-width: $large-screen) {
  .left-menu { top: 70px; }
}

@media screen and (max-width: $small-screen) {
  .left-menu { display: none; }
}

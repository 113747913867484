#tarteaucitronRoot { bottom: 0;
  #tarteaucitronAlertBig { position: absolute !important; background-color: white !important; display:flex; align-items: center; justify-content: center; width: 100vw !important; padding: 20px !important; box-sizing: border-box !important;
    #tarteaucitronDisclaimerAlert { color: $text-color !important; font-size: 12px; flex-shrink:1; flex-grow: 0; flex-basis: 45%;}
    button { margin: 0 10px;
      &:first-of-type { border-radius: $br-size; background-color: $primary; padding: 10px 20px; transition: background-color .2s linear; order:3;
        &::first-letter { display: none !important;}
        &:hover { background-color: $primary-darker;}
      }
      &:last-of-type { color:$text-color !important; text-decoration: underline; font-size: 12px; order:2;
        a:hover { text-decoration: none;}
      }
    }
  }
}

@media screen and (max-width: $small-screen) {
  #tarteaucitronRoot {
    #tarteaucitronAlertBig { flex-direction: column;
      #tarteaucitronDisclaimerAlert { text-align: center; margin-bottom: 10px;}
      button {
        &:last-of-type { margin-bottom: 10px;}
      }
    }
  }
}

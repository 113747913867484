.calculator-list {
  &__container { display: flex; flex-wrap: wrap; justify-content: center; align-items:stretch;}
  &__item { display: block;
            width: 45%; min-height: 140px; margin: 1%; padding: 10px;
            border:1px solid $secondary;
            line-height: 1; font-size: 0;
  }
  &__item-title { width: 100%; margin-bottom: 0; align-self: flex-end; margin-bottom: 10px; }
  &__item-desc { display: inline-block; width:  70%; vertical-align: top;
    h3 { font-size: $fz--text; font-family: 'Montserrat', sans-serif; font-weight:700; color:$secondary-darker;}
    p { font-size: $fz--text; margin-bottom: 0; font-family: 'Montserrat', sans-serif; font-weight:200;}
  }
  &__btn-container { display: inline-block; width: 30%;  text-align: right;}
}

.calculator {
  div[aria-hidden='true'] {
    display: none;
  }
  &__container { overflow: hidden; flex-grow: 1;
    .inner-container { position: relative; z-index: 1; height: 100%;
      &:before,
      &:after { content:''; height: 100%; min-width: 150px; width: 15%; max-width: 450px; position: absolute; top: 0; z-index: -1; }
      &:before { right: 100%; background: transparent url('../../images/front/calc-bg-left.png') center bottom no-repeat; background-size: cover; }
      &:after { left: 100%; background: transparent url('../../images/front/calc-bg-right.png') center bottom no-repeat; background-size: cover; }
      form { height: 100%; display:flex; flex-direction: column;}
    }
  }
  &__nav {
    ol, ul { display:flex; justify-content: space-between; align-items: stretch;
      > li{ height: 44px; }
    }
    &--results { display:flex; background: $primary; align-items: center; }
  }
  &__header-title { display: inline-block; margin-bottom: 0; padding:10px 20px; margin-left: 50px; flex-grow: 1;
                    font-size: $fz--h2; color:white; line-height: 30px; text-transform: initial; font-family: 'Montserrat', sans-serif; font-weight:700;
                  }
  &__result-options {margin-right: 20px;}
  &__nav-step { display:inline-flex; padding:0 20px; flex-grow: 1; min-width: 250px;
    p { display:inline-flex; align-items: center; height: 100%; }
    i { display: inline-block; width: 30px; height: 30px; border-radius:50%; margin-right: 10px;
        background-color: $secondary;
        font-size: 1.25rem; color:white; font-family: 'Montserrat', sans-serif; font-weight:200; text-align: center; line-height: 33px; vertical-align: middle;
    }
    span { display: inline-block; font-size: $fz--text; font-family: 'Montserrat', sans-serif; font-weight:200; text-transform: initial; color:$primary-darker;}
    &.checked { background: $primary;
      span { font-family: 'Montserrat', sans-serif; font-weight:700; color:white; }
      &.current span { text-decoration: underline; }
    }
    &:last-of-type { min-width: 0; justify-content: flex-end;
      &[aria-selected='true'] {background: transparent;}
      input[type="submit"] { display: none;}
      a { display: inline-block;}
    }
    &:nth-last-child(2) {
      &[aria-selected='true'],
      &.checked {
        & + .calculator__nav-step {
          a { display: none; }
          input[type="submit"] { display: inline-block; padding: 13px 30px 15px 30px; }
        }
      }
    }
    &:focus { outline:none;}
    &--hydrogen,
    &--geothermal,
    &--hydrovehicle {
      &:last-of-type { flex-shrink:1; flex-grow: 0;}
    }
    &:hover { cursor:pointer;}
  }

  &__cat-container { flex-grow: 1; display:flex; flex-direction: column; justify-content:center;
                     width: 90%; margin: 0 auto; padding-top: 30px;
                    }
  &__cat-content { display:flex; justify-content: space-between;}

  &__inside-nav-container { display: none;}

  &__input-list { display:flex; flex-wrap:wrap; flex-grow: 1; justify-content: space-between;
    &--effluent { width: 100%;}
    &--intrant { width: 50%;}
    &--residus { width: 45%; flex-grow:0;}
    &--hydrovehicle { width: 100%;}
    &--effluent {
      fieldset{ width: 33%; }
    }
    &--intrant {
      fieldset { width: 25%; }
    }
    &--residus {
      fieldset { width: 50%; }
    }
    &--hydrogen {
      fieldset { width: 100%; max-width: 300px; }
    }
    &--hydrovehicle{
      fieldset { width: 25%; }
    }
  }

  &__input-container { text-align: center; padding:10px 20px 10px 60px; position: relative; margin-bottom: 30px; width: 100%;
    &:before { position: absolute; bottom:0; left: 0;
               content:''; width: 45px; height: 65px; display: inline-block;
    }

    label { display: block; min-height: 35px;
            font-family: 'Montserrat', sans-serif; font-weight:700; color:$primary; line-height: 1.1; vertical-align: bottom;
          }


    // Lisier Porc
    &--effluent-1 {
      &:before { background: transparent url('../../images/front/calc-porc.svg') center bottom no-repeat;}
    }

    // Lisier Bovin
    &--effluent-2 {
      &:before { background: transparent url('../../images/front/calc-bovin.svg') center bottom no-repeat;}
    }

    // Fumier Bovin
    &--effluent-3 {
      &:before { background: transparent url('../../images/front/calc-bovin.svg') center bottom no-repeat;}
    }

    // Fientes Volailles
    &--effluent-4 {
      &:before { background: transparent url('../../images/front/calc-volaille.svg') center bottom no-repeat;}
    }

    // Fumier Ovins
    &--effluent-5 {
      &:before { background: transparent url('../../images/front/calc-ovin.svg') center bottom no-repeat;}
    }

    // Fumier Equins
    &--effluent-6 {
      &:before { background: transparent url('../../images/front/calc-equin.svg') center bottom no-repeat;}
    }

    // Maïs
    &--intrant-7 {
      &:before { background: transparent url('../../images/front/calc-mais.svg') center bottom no-repeat;}
    }

    //Sorgho
    &--intrant-8 {
      &:before { background: transparent url('../../images/front/calc-sorgho.svg') center bottom no-repeat;}
    }

    // Orge
    &--intrant-9 {
      &:before { background: transparent url('../../images/front/calc-orge.svg') center bottom no-repeat;}
    }

    // Betterave
    &--intrant-10 {
      &:before { background: transparent url('../../images/front/calc-betterave.svg') center bottom no-repeat;}
    }

    // Autres
    &--intrant-11 {
      &:before { background: transparent url('../../images/front/calc-autres-culture.svg') center bottom no-repeat;}
    }

    // Maïs Cives
    &--intrant-12 {
      &:before { background: transparent url('../../images/front/calc-mais.svg') center bottom no-repeat;}
    }

    // Seigle
    &--intrant-13 {
      &:before { background: transparent url('../../images/front/calc-seigle.svg') center bottom no-repeat;}
    }

    // Autres graminés
    &--intrant-14 {
      &:before { background: transparent url('../../images/front/calc-autres-culture.svg') center bottom no-repeat;}
    }

    // Paille de blé
    &--residus-15 {
      &:before { background: transparent url('../../images/front/calc-paille-ble.svg') center bottom no-repeat;}
    }

    // Menues pailles
    &--residus-16 {
      &:before { background: transparent url('../../images/front/calc-menue-paille.svg') center bottom no-repeat;}
    }

    // Pulpes de betterave
    &--residus-17 {
      &:before { background: transparent url('../../images/front/calc-betterave.svg') center bottom no-repeat;}
    }

    // Autres résidus de culture
    &--residus-18 {
      &:before { background: transparent url('../../images/front/calc-autres-culture.svg') center bottom no-repeat;}
    }

    // Déchets alimentaires
    &--residus-19 {
      &:before { background: transparent url('../../images/front/calc-dechets-alim.svg') center bottom no-repeat;}
    }

    // Déchet produits laitiers
    &--residus-20 {
      &:before { background: transparent url('../../images/front/calc-dechets-lacto.svg') center bottom no-repeat;}
    }

    // Tontes de pelouse
    &--residus-21 {
      &:before { background: transparent url('../../images/front/calc-tonte.svg') center bottom no-repeat;}
    }

    // Autres bio-déchets
    &--residus-22 {
      &:before { background: transparent url('../../images/front/calc-autres-dechets.svg') center bottom no-repeat;}
    }

    // hydrogen
    &--hydrogen{
      &:before { background: transparent url('../../images/front/calc-hydrogen.svg') center bottom no-repeat;}
    }

    // Type d'infrastructure
    &--geothermal-1{
      &:before { background: transparent url('../../images/front/calc-geothermal-building.svg') center bottom no-repeat;}
    }

    // Surface
    &--geothermal-2{
      &:before { background: transparent url('../../images/front/calc-geothermal-surface.svg') center bottom no-repeat;}
    }
    // Surface
    &--light-vehicle{
      &:before { background: transparent url('../../images/front/calc-light-vehicle.svg') center bottom no-repeat;}
    }
    // Surface
    &--dumpster{
      &:before { background: transparent url('../../images/front/calc-dumpster.svg') center bottom no-repeat;}
    }
    // Surface
    &--bus{
      &:before { background: transparent url('../../images/front/calc-bus.svg') center bottom no-repeat;}
    }
    // Surface
    &--forklift{
      &:before { background: transparent url('../../images/front/calc-forklift.svg') center bottom no-repeat;}
    }
  }
  &__input-list-title { width: 100%; color:black; font-size: $fz--h4; font-family: 'Montserrat', sans-serif; font-weight:700; text-transform: initial; margin-bottom: 10px; }
  &__stepper-container { line-height: 1; display: inline-flex; align-items: center; vertical-align: top; position: relative; border: 1px solid $border-color;padding: 10px; position: relative; width: 100%;
    input { appearance: none; border:none; -moz-appearance:textfield; text-align: center; font-family: 'Montserrat', sans-serif; font-weight:200; font-size: $fz--h3; width: 100%;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button { appearance:none; margin: 0;}
      &:focus { outline:none;}
    }
  }
  &__stepper-less,
  &__stepper-more { display: inline-block; width: 20px; height: 20px; border-radius: 50%; line-height: 20px; text-align: center; flex-shrink:0;
    background-color: $secondary;
    color:white; font-family: 'Montserrat', sans-serif; font-weight:700;
    user-select: none;
    &:hover { cursor: pointer;}
  }
  &__stepper-unit { position: absolute; top: 100%; right: 10px;
                    display: block; margin-top: 10px;
                    font-size: 14px; white-space: nowrap; color:black;
  }
  &__select-container { line-height: 1; display: inline-block; vertical-align: top; position: relative;
    select {border: 1px solid $border-color;  padding: 10px; background: transparent url('../../images/front/select-drop.svg') right 10px center no-repeat; appearance:none; min-width:250px; }
  }

  &__results { display: flex; justify-content: space-around; margin-top:20px;
    .calculator__result-options { display: none;}
  }
  &__result-col { width: auto; min-width: 250px; max-width: 450px; padding: 0 20px; flex-grow: 1;
    & > :last-child { margin-bottom: 20px;}
    h4 { color:$primary; font-size: 1.25rem; text-transform: initial; font-family: 'Montserrat', sans-serif; font-weight:700; margin-bottom: 0; margin-top: 10px; line-height: 1.1;
      strong { color:black;}
    }
    p { font-family: 'Montserrat', sans-serif; font-weight:700;}
    ul {
      li { position: relative; padding-left: 30px;
        &:before { content:'';
                   position: absolute; top: 6px; left: 0;
                   display: inline-block; width: 25px; height: 8px;
                   background-color: $primary;
                 }
      }
    }
    small { display: inline-block; font-size: $fz--text--small; line-height: 1; margin-bottom:0; margin-top: 15px; }
  }
  &__contact-content { border:1px solid $secondary; padding: 20px; text-align: center;
    p { font-family: 'Montserrat', sans-serif; font-weight:200; font-size: $fz--h4;}
  }
  &__result {  font-size: 1.25rem;  font-family: 'Montserrat', sans-serif; font-weight:700; margin-bottom: 0; color:black; line-height: 0.8;
    b { white-space: nowrap;}
  }
}

@media screen and (max-width: $large-screen) {
  .calculator-list {
    &__item { min-height: 200px;}
    &__item-title { text-align: center;}
    &__item-desc { width: 100%; text-align: center;
      h3 { margin-bottom: 10px;}
    }
    &__btn-container { text-align: center; width: 100%;}
    &__item-link { margin-top: 10px;}
  }
  .calculator {
    &__nav-step { min-width: 0;
      span { width: calc(100% - 40px); line-height: 1; vertical-align: middle;}
      &:last-of-type { margin-right: 10px;}
    }
    &__cat-container { padding-top: 10px;}
    &__input-container { padding: 0 20px 0 30px;
      &:before { width: 30px; height: 50px;}
      label { font-size: 0.75rem; min-height:30px;  }
    }
    &__contact-content { padding: 20px;}
  }
}

@media screen and (max-width: $small-screen) {
  .calculator-list {
    &__item { width: 100%; min-height: 0;}
    &__item-desc {
      h3 { margin-bottom: 10px;}
    }
  }
  .calculator {
    &__container {
      .inner-container { width: 100%;
        &:before,
        &:after  { content:'';}
      }
    }
    &__header-title { margin-left: 20px;}
    &__nav--results {
      .calculator__result-options { display: none;}
    }
    &__nav-step { display: none;  padding: 10px 20px;
      &[aria-selected='true'] { display: block; flex-grow: 0; width: 85%;}
    }

    &__cat-content { flex-wrap: wrap;}

    &__inside-nav-container { display:flex; width: 100%; margin-top: 20px;
      &--one-btn { justify-content: flex-end;}
      &--two-btn { justify-content: space-between;}
    }

    &__input-list { padding: 0 30px; width: 100%;}
    &__input-container { width: 100%;
      label { font-size: 1rem; min-height:30px; }
    }
    &__stepper-container { width: 80%;
      .form-control { width: calc(100% - 50px);}
    }
    &__select-container {width: 90%;
      select { width: 100%;}
    }
    &__results { flex-wrap: wrap;
      .calculator__result-options { display: inline-block;}
    }
    &__result-col { width: 100%; max-width: none;}

  }
}

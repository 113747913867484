
form {
  p { }
  label { }
  input.text,
  input.title,
  input[type=email],
  input[type=password],
  input[type=tel],
  input[type=text],
  select,
  textarea {
    &:focus { outline:none;}
  }
  select { }
}

input[type=submit],
button {
  &:hover { }
}

//couleurs du projet
$primary: #008A8B;
$primary-lighter: #59B4C4;
$primary-lightest: #7FC4C5;
$primary-darker: #006B78;
$secondary: #F78A31;
$secondary-lighter: lighten($secondary, 10%);
$secondary-lightest: lighten($secondary, 30%);
$secondary-darker: #ED6B00;
$tertiary: #E50051;
$tertiary-lighter: lighten($tertiary, 10%);
$tertiary-darker: darken($tertiary, 10%);
$neutral : #707070;
$neutral-light : #A29FA0;


$engie-blue : #00aaff;

$light-bg: #F0F0F0;
$grey-bg : #D0D2D3;
$overlay-bg : #939393;

$border-color: #E6E6E6;

$text-color: $neutral;
$text-color-light: transparentize(black, .7);

$shadow: 1px 3px 0 transparentize(black, .8);
$shadow-text: 1px 1px 0 transparentize(black, .8);


// Font sizes
$fz--big-title: 2.375rem ;
$fz--h1: 1.875rem;
$fz--h2: 1.25rem;
$fz--h3: 1.125rem;
$fz--h4: 1.125rem;
$fz--text: 1.125rem;
$fz--text--small: .75rem;
$fz--button: 1rem;

$fz--big-title-mobile: 1.25rem ;
$fz--h1-mobile: 1.25rem;
$fz--h2-mobile: 1rem;
$fz--h3-mobile: 1rem;
$fz--h4-mobile: 1rem;

// // font
// $light: 'calibri-light';
// $reg: 'calibri';
// $bold: 'calibri-bold';
// $clanbook: 'clan-book';
// $clanbold: 'clan-bold';
// $clanblack: 'clan-black';

// Border radius sizes
$br-size : 30px;
$br-size--med : 20px;
$br-size--small : 10px;

// Screen sizes
$small-screen : 64rem;
$large-screen : 80rem;
$xlarge-screen : 1400px;

.mobile-nav { display: none;}

@media screen and (max-width: $small-screen) {
  .mobile-nav { display: block; position:fixed; width: 100%; bottom: 0; height: 60px; z-index: 10; background-color: white;
    &__container { display: flex; margin: 0 auto; width: 100%; max-width: 350px; height: 100%; align-items: center; justify-content: space-around;}
    &__item {}
    &__link {
      i { display: inline-block; line-height: 1; vertical-align: middle; opacity: 1; transition: opacity .3s linear;}
      &--nav-toggle {
        i { width: 21px; height: 18px;
            background: transparent url('../../images/front/menu.svg') center no-repeat; background-size: contain;
          }
      }
      &--calc {
        i { width: 23px; height: 32px;
            background: transparent url('../../images/front/picto-calc.svg') center no-repeat; background-size: contain;
          }
      }
      &--contact {
        i { width: 28px; height: 19px;
            background: transparent url('../../images/front/picto-contact.svg') center no-repeat; background-size: contain;
          }
      }
      &--search {
        i { width: 28px; height: 19px;
            background: transparent url('../../images/front/search.svg') center no-repeat; background-size: contain;
          }
        &.open {
          i { background: transparent url('../../images/front/close-primary.svg') center no-repeat; background-size: contain; }
        }
      }
    }
  }
}

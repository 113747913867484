.footer { position: relative;
          display: flex; justify-content: space-between; align-items: center;
          padding-bottom: 6px;
          box-shadow: 0px 10px 40px #005BA91A inset;background-color: white;
  &:after { content:'';
            position: absolute; bottom: 0; left: 0;
            width: 100%; height: 6px;
            background: transparent linear-gradient(270deg, $primary 0%, $primary-lightest 100%) 0% 0% no-repeat padding-box;
  }
  &__brand { display:flex; flex-direction:column; align-items: flex-end;
             padding: 60px 60px 40px;
           }
  &__brand-logo { width: 180px; margin-bottom: 5px;
    img {width: 100%;}
  }
  &__brand-line-container { display:flex; align-items:flex-end;}
  &__brand-line { max-width: 125px;
                  font-size: 10px; letter-spacing: 0.25px; line-height: 25px; font-family: 'clan-book'; font-weight: 700; color: $engie-blue;
                }
  &__engie-logo { width: 60px;margin-left: 5px;}
  &__nav { position: relative; align-self:stretch;
           flex-grow: 1;
           margin: 0 100px; padding: 60px 60px 40px 70px;
           display:flex; align-items:center;
    &:before { content:'';
               position: absolute; top: 0; left: 0;
               width: 8px; height: 100%;
               background: transparent url('../../images/front/filet-neutral-lightest-opa-vert.svg') top 5px left repeat-y; background-size: 8px 16px;

    }
  }
  &__nav-container { line-height: 1; margin-right: 60px; margin-bottom: 10px;
    &:last-of-type { margin-bottom: 0;}
  }
  &__nav-item { display: inline-block;
    a { display: inline-block; padding: 0 10px;
        font-size: 12px; letter-spacing: .3px; line-height: 15px; color:$text-color; text-decoration: none; font-family: 'clan-book';
      &:hover { text-decoration: underline;}
    }
    &--bold {
      a { font-family: 'clan-medium';}
    }
  }
  &__copyright { padding: 0 60px;}
  &__copyright-real { margin-bottom: 10px;
                      font-size: 10px; text-align: right; letter-spacing: 0.25px; line-height: 15px; font-family: 'clan-book';
    a { text-decoration: none; font-family: 'clan-medium'; color:$text-color;
      &:hover { text-decoration: underline;}
    }
  }
  &__copyright-rights { margin-bottom: 0;
                        font-size: 10px; text-align: right; letter-spacing: 0.25px; line-height: 15px; font-family: 'clan-medium'; color:$primary;
  }
  &__rs { align-self:cetner; margin-bottom: 5px;}
  &__rs-list {display: flex; align-items:center; list-style:none;}
  &__rs-item {display: block; width: 30px; height: 30px; margin-right: 10px;
    &:hover {opacity: .8;}
  }
  &__rs-item-link { display: inline-block;width: 30px; height: 30px; 
                    position: relative;
                  }
  &__rs-item-picto {display:inline-block;width: 100%; height: 100%;
                    position: absolute; top: 0; left: 0;
    &--linkedin {background: transparent url('../../imageS/front/linkedin.svg') center bottom no-repeat; background-size: contain;}
    &--twitter {background: transparent url('../../imageS/front/twitter.svg') center bottom no-repeat; background-size: contain;}
    &--youtube {background: transparent url('../../imageS/front/youtube.svg') center bottom no-repeat; background-size: contain;}
  }

}

@media screen and (max-width: $large-screen) {
  .footer { display: block;
    &__brand { width: 100%; padding-left: 40px; padding-bottom: 30px;}
    &__nav { width: 100%; margin: 0; padding: 40px;
      &:before {
        width: 100%; height: 8px;
        background: transparent url('../../images/front/filet-neutral-lightest-opa.svg') top left repeat-x; background-size: 16px 8px;
      }
    }
    &__nav-item {
      &:first-of-type {
        a { padding-left: 0;}
      }
    }
    &__copyright { width: 100%; padding: 10px 40px 30px 40px;}
    &__copyright-real { display: inline-block; margin-right: 20px;}
    &__copyright-rights { display: inline-block;}
  }
}

@media screen and (max-width: $small-screen) {
  .footer {
    &__nav { padding-bottom: 10px;}
    &__nav-item { display: block; margin-bottom: 10px;
      a { padding: 0;}
    }
    &__copyright { }
    &__copyright-real { display: block;text-align: left;}
    &__copyright-rights { display: block;text-align: left;}
  }
}

.sr-only { position: absolute; margin: -1px 0 0 -1px; padding: 0; display: block; width: 1px; height: 1px; font-size: 1px; line-height: 1px;
  overflow: hidden; clip: rect(0,0,0,0); border: 0; outline: 0;
}

.page {
  &__container {overflow: hidden;}
  &__section { display:flex; min-height:100vh; height: auto;
    &--fullheight { height: 100vh; min-height:850px;}
    &--realisations-list { position: relative; background: transparent url('../../images/front/realisations-list-bg.jpg') center no-repeat; background-size: cover; background-attachment: fixed;
      .page {
        &__sidebar,
        &__fill { background-color: transparent;}
      }
    }
    &--overlayed {
      &:after { content:'';
                position: absolute;top: 0; left: 0; z-index: 0;
                width: 100%; height: 100%;
                background: transparent linear-gradient(128deg, #04B3FF 0%, #005BA9 100%) 0% 0% no-repeat padding-box;
                opacity: 0.8;
      }
      .page__content { position: relative; z-index: 2;}
    }
  }
  &__sidebar-header { position: absolute; top: 0; left: 0; z-index: 3;
                      width: 100%; display:flex; justify-content: flex-start; align-items: center; min-height: 130px;
                      padding: 70px 60px 30px 60px;
    &:after { content:'';
              position: absolute; bottom: 0; left: 50%; transform: translateX(-50%);
              display: block; height: 8px; width: calc(100% - 120px);
              background: transparent url('../../images/front/filet-neutral-lightest-opa.svg') top left repeat-x; background-size: 16px;
    }
    &--no-after {
      &:after {content: none;}
    }
    .btn { position: absolute; right: 60px; top: 70px; transform: translateY(-40%);}
    .content__sector-name,
    .content__solution-name,
    .content__segment-name {  margin-bottom: 0;}
  }
  &__sidebar-header-title { margin-bottom: 0;
                            font-size: $fz--h2; letter-spacing: 0.7px; line-height: 40px; font-family: 'clan-book';
    &--icon {
      i { margin-right: 20px;}
    }
  }
  &__sidebar { width: 520px; flex-shrink:0; position: relative; overflow: hidden;
               background-color: white;
    &--large { width: 1040px; }
    &--illustration {z-index: 2;}
  }
  &__fill { flex-grow:1; position: relative; min-height:850px;
            background-color: white;
    &--illustration-tertiaire { background-position: right center; background-size: contain;}
    &--overlayed {
      & > img { position: absolute; top: 0; left: 0;
            height: 100%;
      }
      &:before { content:'';
                position: absolute;top: 0; left: 0; z-index: 1;
                width: 100%; height: 100%;
                background: transparent linear-gradient(128deg, $primary 0%, $primary-lightest 100%) 0% 0% no-repeat padding-box;
                opacity: 0.8;
      }
    }
    &--shadow-left{ box-shadow: 0px 10px 40px #005BA91A inset;}
    &--sticky { height: 100vh; position: sticky; top: 0;}
    &--hide-overflow { overflow: hidden;}
    &--illustration { min-height: none;}
    &--error {position: relative; background: transparent url('../../images/front/error-bg.jpg') center no-repeat; background-attachment: fixed;
      &:after { content:'';
                position: absolute;top: 0; left: 0; z-index: 0;
                width: 100%; height: 100%;
                background: transparent linear-gradient(128deg, #04B3FF 0%, #005BA9 100%) 0% 0% no-repeat padding-box;
                opacity: 0.8;
      }
      .page__content { position: relative; z-index: 2;}
    }
  }
  &__fixed-bg-container { width: 100%; height: 100%; position: sticky; top: 0; left: 0;
    &--overlayed {
      &:before { content:'';
                position: absolute;top: 0; left: 0; z-index: 1;
                width: 100%; height: 100%;
                background: transparent linear-gradient(128deg, #04B3FF 0%, #005BA9 100%) 0% 0% no-repeat padding-box;
                opacity: 0.8;
      }
    }
  }
  &__fixed-bg { width: 100%; height: 100%; object-fit:cover;}
  &__fixed-bg-content { width: 100%; height: 100%;position: absolute; top: 0; left: 0; z-index: 2;
    .page__content { height: auto;}
  }
  &__fixed-content { width: 100%; height: 100%;object-fit:cover;}
  &__content { padding: 100px 60px 30px 90px;height: 100%; position: absolute; z-index: 2; top: 0; left: 0;
    &--big-headspace { padding-top: 150px;}
    &--ultra-big-headspace { padding-top: 250px;}
    &--cols { display:flex;}
    &--auto-height { height: auto;}
    &--fullwidth { padding-left: 0; padding-right: 0;}
    &--no-bottom-padding { padding-bottom: 0;}
    &--contact { padding-left: 60px;}
    &--center { text-align: center;
      h1,
      h2 { margin: 0 auto;}
      * { text-align: center;}
    }
  }
  &__content-col { flex-grow: 1; padding-right: 110px;
    &:first-of-type { padding-right: 100px;}
    &--small { width: 350px; flex-shrink: 0; flex-grow: 0; padding-right: 50px;}
  }
  &__content-footer { position: absolute; bottom: 0;left: 0;}
  &__half-height-container { height: 50%; overflow: hidden;}
}

@media screen and (max-width: $xlarge-screen) {
  .page {
    &__sidebar { width: 460px;
      &--large { width: 768px;}
    }
    &__content { padding-top: 80px; padding-left: 80px;
      &--big-headspace { padding-top: 200px;}
      &--ultra-big-headspace { padding-top: 250px;}
      &--sticky { position:static;}
    }
    &__content-col { padding-right: 80px;
      &--small { width: 240px;}
    }
  }
}
@media screen and (max-width: $large-screen) {
  .page {
    &__section { flex-wrap:wrap; height: auto; min-height: auto;
      &--fullheight { height: auto;}
    }
    &__sidebar { width: 100%;}
    &__sidebar-header { position: relative; min-height: 0; padding-top: 30px;
      .btn { top: 35px;}
    }
    &__fill { width: 100%; min-height:284px;
      &--sticky { height: auto;}
    }
    &__content {position: static;
      &--big-headspace { padding-top: 100px;}
      &--ultra-big-headspace { padding-top: 120px;}
      &--fullwidth { padding-left: 0; padding-right: 0;}
    }
    &__fixed-bg-container { height: auto;min-height:284px;}
    &__fixed-bg { position: absolute; top: 0; left: 0;}
    &__fixed-bg-content { position:relative;

    }
  }
  img.page__fixed-bg-content { display: block; object-fit:contain; width: 60%; height: 60%; margin: 0 auto;}
  .anchor-link {display: none;}
  .home-intro { position: relative !important; padding-bottom: 150px !important;}
}

@media screen and (max-width: $small-screen) {
  .page {
    &__sidebar-header { padding: 30px;position: absolute; top: 0; left: 0; z-index: 3; min-height: 90px;
      &:after {width: calc(100% - 60px);}
    }
    &__content { padding:  30px 30px 60px 30px;
      &:first-of-type { padding-top: 60px;}
      &--big-headspace { padding-top: 50px !important;}
      &--ultra-big-headspace { padding-top: 150px !important;}
      &--fullwidth { padding-left: 0; padding-right: 0;}
      &--cols { flex-wrap: wrap;}
    }
    &__content-col { padding-right: 0 !important; width: 100%; padding-bottom: 50px !important;

    }
    &__fill {
      .btn-container {min-height: 350px;}
    }
  }
  .anchor-link { padding: 0 30px; margin-bottom: 20px;
    &--achievements { display: block;}
  }
}

.breadcrumb { margin-bottom: 20px;
  .return { margin-right: 20px; color: $text-color;
    &:hover { text-decoration: none;}
    i { display: inline-block; background: transparent url('../../images/front/arrow-left-grey.svg') center no-repeat; width: 15px; height:10px; line-height: 1; vertical-align: middle; background-size: contain; margin-right: 5px;}
  }
  ul { display: inline-block; list-style:none;
    li { display: inline-block;
      &:before { content:none;}
      &:not(:last-of-type){
        &:after { content:'>';}
      }
      a { color: $text-color;
        &:hover { text-decoration: none;}
      }
    }
  }
}

@media screen and (max-width: $small-screen) {
  .breadcrumb {
    ul { display: none;}
  }
}

.widget { margin-bottom: 30px;
  .mb-0 & { margin-bottom: 0;}
  &--text {
    p { padding-left: 35px; position: relative;
      &:before { content:'';
                 position: absolute; top: 5px; left: 0;
                 display: inline-block; width: 25px; height: 8px;
                 background-color: $primary;
      }
    }
  }
  &--image { }
  &--solution { position: relative; display: block;
                padding-right: 80px; padding-left: 35px;
                text-decoration: none;
                color:$text-color;
    &:before { content:'';
               position: absolute; top:8px; left: 0;
               display: inline-block; width: 25px; height: 8px;
               background-color: $primary;
    }
    h3 { color:$primary; margin-bottom: 0;}
    div { position: absolute; top: 50%; right: 20px; transform: translateY(-50%);}
  }
  &--pillar { text-align: center; padding-top: 40px;}
  &__pillar-link { position: relative;
                   display: inline-block; width: 210px; height: 210px;
                   background: $primary-lighter;
                   line-height: 210px; text-decoration: none;
    img { position: absolute; top: 0; left: 50%; transform: translate(-50%,-35%);}
    h3 { display: inline-block; padding: 0 20px; padding-top: 20px;
         line-height: 1.2; font-family: 'Montserrat', sans-serif; font-weight:200; font-size: $fz--h2; text-transform: uppercase; color:white; vertical-align: middle; }
    i { position: absolute; bottom: 0; left: 50%; transform: translate(-50%,20%);
       display: inline-block; width: 50px; height: 50px;
       background-color: $primary;
       transition: background-color .2s linear;
      &:before { content:'';
                position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%) scale(1);
                display: inline-block; width: 16px; height: 16px;
                background: transparent url('../../images/front/arrow-right-white.svg') bottom center no-repeat; background-size: contain;
                line-height: 1;
                transition: transform .2s linear;
      }
    }
    &:hover {
      i { background-color: $primary-darker;
        &:before { transform: translate(-50%,-50%) scale(1.2);}
      }
    }
  }
  &__img-container {
    img {width: 100%; height: auto;}
  }
  &__video-container { padding-top: 56.25%; position: relative;
    iframe { position: absolute; top: 0; left: 0;
             width: 100%; height: 100%;
    }
  }
}

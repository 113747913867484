.header {
  &__container { position: absolute; top: 0;left: 0; width: 100%; z-index: 1000;}
  &__content { display: flex; align-items: center; padding: 30px 50px 30px 90px; }
  &__spacer { flex-grow: 1; }
  &__logo {
    a,
    img {display: inline-block; line-height: 1; vertical-align: middle;}
    .home & {display: none;}
  }
  &__lang-switcher { margin-right: 10px; padding: 10px 20px; min-width: 96px; border:1px solid $primary; background: #fff; }
  &__lang-switcher-container { line-height: 1;}
  &__lang-switcher-item { display: inline-block;
                          border-right: 2px solid $primary;
                          line-height: 0.8;
    a { display: inline-block;padding-right: 5px;
        text-decoration: none; color: $primary; line-height: 1; vertical-align: middle; font-family: 'Montserrat', sans-serif; font-weight:400;
      &.active { font-family: 'Montserrat', sans-serif; font-weight:700; color: $secondary; }
    }
    &:last-of-type { border: none;
      a { padding-right: 0;}
    }
  }
  &__search-container { position: relative; margin-right: 10px;}
  &__search-toggle { display: inline-block; width: 38px; height: 38px; vertical-align: middle;
                     background: #fff url('../../images/front/search.svg') center no-repeat; background-size: 50%; border: 1px solid $primary;
    &:focus { outline: auto; outline-color: blue; }
    &:hover{ cursor: pointer; }
    &.open {background: $primary url('../../images/front/close.svg') center no-repeat; background-size: 50%; border-radius:50%; }
  }
  &__search { position: absolute; top: calc(100% + 20px); left: 0;
              display: none; width: 100vw; min-width: 30vw; max-width: 50vw; padding: 20px;
              background-color: white;
              box-shadow: 0px 0px 5px transparentize($neutral, .5);
    &.open {display: block;}
  }
  &__search-field { width: 100%;
                    outline:none;
                    border:none; border-bottom: 2px solid $primary; padding: 5px; color:$text-color;
                    &:focus { border-color: $tertiary;}
                  }
  &__search-results { max-height: 300px; margin-top: 10px;
    a { position: relative;
        display: block; padding: 10px 0;
        color:$text-color; text-decoration: none;
      &:after { content:'';
                position: absolute; bottom: 0; left: 0;
                display: inline-block; height: 1px; width: 35%;
                background-color: $primary;
      }
    }
  }
  &__main-nav-toggle { display: inline-block; width: 38px; height: 38px; border: 1px solid $primary; margin-right: 10px;
                       background: white url('../../images/front/menu.svg') center no-repeat; background-size: 50% auto;
                       &:hover{ cursor: pointer; }

  }
  &__contact { display: inline-block; width: 38px; height: 38px; border: 1px solid $primary; margin-right: 10px;
    background: transparent url('../../images/front/picto-contact.svg') center no-repeat; background-size: 50% auto;
  }
}

.temporary-links {position: absolute; bottom: 0;left: 0; z-index: 10;}

@media screen and (max-width: $large-screen) {
  .header {
    &__content { padding: 10px 50px 10px 60px;}
    &__logo { width: 100px;
      img { width: 100%; height: auto; }
    }
  }
}

@media screen and (max-width: $small-screen) {
  .header {
    &__container {
      .inner-nav { display: none;}
    }
    &__content { padding: 10px 0; width: 90%; margin: 0 auto; padding: 10px 2%;}
    &__main-nav-toggle,
    &__search-toggle { display: none;}
    &__logo { width: 130px;
      a { display: inline-block;}
    }
    &__lang-switcher,
    &__contact {display: none;}
    &__spacer {
    }
    &__search-container { margin: 0; }
    &__search { position: fixed; top: 0; left: 0; right: auto; z-index: 1000;
                overflow: hidden; max-width: none; width: 100vw; height: calc(100vh - 60px); padding: 20px 10px;
                border-radius:0; box-shadow:none;}
    &__search-results { max-height: 95%;}
  }
}

// EXEMPLE

@font-face {
  font-family: 'calibri';
  font-style: normal;
  font-display: swap;
  src: local('calibri'), url('../../../assets/fonts/calibri.woff') format('woff');
}

.calibri {font-family: 'calibri' !important; font-weight: normal;}

@font-face {
  font-family: 'calibri-light';
  font-style: normal;
  font-display: swap;
  src: local('calibri-light'), url('../../../assets/fonts/calibri-light.woff') format('woff');
}

.calibri-light {font-family: 'calibri-light' !important; font-weight: normal;}


@font-face {
  font-family: 'calibri-bold';
  font-style: normal;
  font-display: swap;
  src: local('calibri-bold'), url('../../../assets/fonts/calibri-bold.woff') format('woff');
}

.calibri-bold {font-family: 'calibri-bold' !important; font-weight: normal;}

@font-face {
  font-family: 'clan-book';
  font-style: normal;
  font-display: swap;
  src: local('clan-book'), url('../../../assets/fonts/clan-book.woff') format('woff');
}

.clan-book {font-family: 'clan-book' !important; font-weight: normal;}

@font-face {
  font-family: 'clan-medium';
  font-style: normal;
  font-display: swap;
  src: local('clan-medium'), url('../../../assets/fonts/clan-medium.woff') format('woff');
}

.clan-medium {font-family: 'clan-medium' !important; font-weight: normal;}


@font-face {
  font-family: 'clan-black';
  font-style: normal;
  font-display: swap;
  src: local('clan-black'), url('../../../assets/fonts/clan-black.woff2') format('woff2');
}

.clan-black {font-family: 'clan-black' !important; font-weight: normal;}

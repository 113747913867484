body { line-height: 1.3; font-family: 'calibri', sans-serif; color:$text-color; font-size: $fz--text;}

h1,
.h1 { font-size: $fz--h1; font-family: 'clan-bold', sans-serif; margin-bottom: 40px; color:$primary;}
h2,
.h2 { font-size: $fz--h2; font-family: 'clan-bold', sans-serif; color:black; margin-bottom: 10px;}
h3,
.h3 { font-size: $fz--h3; font-family: 'clan-bold', sans-serif; margin-bottom: 10px;}
h4,
.h4 { font-size: $fz--h4; font-family: 'clan-book', sans-serif; margin-bottom: 10px;}

.content { height: 100%; position: relative;
  &__segment-name { background-color: $primary-lighter;}
  &__pre-title { position: relative;
                 display: block; padding-left: 42px; margin-bottom: 30px; margin-top: -7px;
                font-family: 'clan-medium'; font-size: $fz--text--small; text-transform: uppercase; color:white;
    &:before { content:'';
               position: absolute; top: 50%; left: 0; z-index: 1;
               display: block; width: 30px; height: 6px; margin-top: -4px;
               background-color: white;
    }
    &--primary { color:$primary;
      &:before { background-color: $primary;}
    }
    &--no-hr {
      &:before { content:none;}
    }
  }
  &__title { position: relative;
             padding-bottom: 40px; margin-bottom: 35px;
             font-size: $fz--big-title; font-family: 'clan-medium'; line-height: 1.1; color:$text-color;
    &:after {content:'';
             position: absolute; bottom: 0; left: 0; z-index: 1;
             display: block; width: 30px; height: 6px;
             background-color: $neutral-light;
    }
    &--white {color:white;
      &:after,
      &:before { background-color: white;}
    }
    &--small {max-width: 440px; }
    &--no-hr { padding-bottom: 0;
      &:after { content:none;}
    }
    &--cap { text-transform: uppercase;}
    &--hr-up { padding-top: 40px; padding-bottom: 0;
      &:after { top: 0; bottom: auto;}
    }
    &--hr-up-down { padding-top: 40px; padding-bottom: 40px;
      &:before {content:'';
               position: absolute; top: 0; left: 0; z-index: 1;
               display: block; width: 30px; height: 6px;
               background-color: $neutral-light;
      }
    }
  }
  &__sub-title { max-width: 280px; margin-bottom: 35px;
                 font-size: $fz--h2; color:white; font-family: 'clan-book';
    &--large { max-width: none;}
  }
  // &__wysiwyg {
  //   h2 {font-size: $fz--h2; color:$neutral-light; margin-bottom: 20px;}
  //   h3 { font-size: $fz--h3;  color:$text-color;}
  //   h4 {font-size: $fz--h5; text-transform: uppercase; color:$text-color;}
  //   hr { margin: 0 0 30px 0 ; width: 30px; height: 6px;
  //        background-color: $text-color-light; border:none; opacity: 0.3;
  //        text-align: center;
  //   }
  //   ul { margin-bottom: 20px; padding-left: 20px;
  //     li { position: relative;
  //          margin-bottom: 25px; padding-left: 30px;
  //          font-size: $fz--text--small;
  //       strong,
  //       b { font-family: 'lato-bold';}
  //       &:before { content:'';
  //                  position: absolute; top: 7px; left: 0;
  //                  display: inline-block; width: 15px; height: 6px;
  //                  border-radius: 3px; background-color: $primary-lighter;
  //                  line-height: 1; vertical-align: middle;
  //                }
  //     }
  //   }
  //   ol { margin-bottom: 20px; padding-left: 20px;
  //        counter-reset: my-awesome-counter;
  //     li { counter-increment: my-awesome-counter;
  //          font-size: $fz--text--small;
  //          strong,
  //          b { font-family: 'lato-bold';}
  //       &:before {
  //         content: counter(my-awesome-counter) ". ";
  //         color: $primary-lighter; font-size: $fz--text--small;
  //       }
  //     }
  //   }
  // }
}

p { margin-bottom: 20px;
  em { font-style: italic;}
  b, strong { font-family: 'calibri-bold', sans-serif;}
}

.segment-list {
  &__title { position: relative;
             padding-top: 40px; max-width: 195px; margin-bottom: 35px;
             color: $text-color; font-size: $fz--h3; font-weight: 200;
    &:after { content:'';
              position: absolute; top: 0; left: 0;
              display: block; width: 30px; height: 6px;
              background-color: $neutral-light;
    }
  }
}

.row {
  ul { margin-bottom: 20px;
    li {
      &:before { content:'';
                 display: inline-block; width: 5px; height: 5px; margin-right: 5px;
                 border-radius: 50%; background-color: $primary;
                 line-height: 1; vertical-align: middle;
               }
    }
  }
  ol { margin-bottom: 20px;
       counter-reset: my-awesome-counter;
    li {counter-increment: my-awesome-counter;
      &:before {
        content: counter(my-awesome-counter) ". ";
        color: $primary; font-weight: bold; font-size: $fz--text--small;
      }
    }
  }
}

figcaption {font-family: 'clan-book', sans-serif; font-style: italic; font-size: $fz--text; margin-top: 5px; }


@media screen and (max-width: $large-screen ) {
  h1,
  .h1 { font-size: $fz--h1-mobile; font-family: 'clan-bold', sans-serif; text-transform: uppercase;}
  h2,
  .h2 { font-size: $fz--h2-mobile; font-family: 'clan-book', sans-serif; line-height: 1.125rem; text-transform: uppercase; margin-bottom: 20px;}
  h3,
  .h3 { font-size: $fz--h3-mobile; font-family: 'clan-bold', sans-serif; text-transform: uppercase;margin-bottom: 20px;}
  h4,
  .h4 { font-size: $fz--h4-mobile; font-family: 'clan-book', sans-serif; text-transform: uppercase;margin-bottom: 20px;}
}

.illustration-pointer {
  &__container { width: 1%; height: 1%; position: absolute; top: 50%; left: 50%; text-align: center; transform-origin: center; transform: translate(-50%,-50%);
    &:before { content: '';
               position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
               width: 0; height: 0; border: 1px solid white; border-radius:50%;
               transition: all .3s linear;
             }
    h2 { position: absolute; bottom: 35px; left: 50%; transform: translateX(-50%);
         display: inline-block; padding: 8px 20px 8px 20px;
         background-color: #fff;
         color: $primary-darker; font-family: 'Montserrat', sans-serif; font-weight:400; font-size: $fz--text; font-weight: normal; text-decoration: none; line-height: 1; white-space: nowrap; text-transform: initial;
    }
    a { display: inline-block; padding: 15px 30px; position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);
        background-color: $secondary-darker;
        color: white; font-family: 'Montserrat', sans-serif; font-weight:700; text-decoration: none; line-height: 1; white-space: nowrap;
        transition: background-color .3s linear;
      &:before,
      &:after { position: absolute; left: 50%; transform: translateX(-50%); margin-top: 0; margin-left: 0;top: calc(100% + 10px);
                content:''; width: 0; height: 0;
                border-style: solid; border-width: 20px 12px 0 12px;
              }
      &:before { border-color: transparentize(black, 0.9) transparent transparent transparent;

               }
      &:after { border-color: $secondary transparent transparent transparent;
                transition: border-color .3s linear;
      }
    }
    &:hover,
    &:active,
    &:focus { cursor: pointer;
      &:before { width: 270px; height: 270px;  }
      a { background-color: $primary-darker;
        &:before,
        &:after { top: calc(100% + 10px);}
        &:after { border-color: $primary-darker transparent transparent transparent; }
      }
    }
    &--industrie-decarbonee {top: 27%; left: 63%;}
    &--agriculture-durable {top: 70%; left: 79%;}
    &--ecoquartier {top: 78%; left: 46%;}

    &--indus-verdir { top: 22%; left: 62%;}
    &--indus-biodechets { top: 50%; left: 69%;}
    &--indus-mobility { top: 81%; left: 22%;}
    &--indus-energy { top: 83%; left: 43%;}

    &--agri-methanisation {top: 39%; left: 67%; }
    &--agri-biodechets {top: 67%; left: 60%; }
    &--agri-gnv { top: 78.6%; left: 21.7%;}
    &--agri-chaleur { top: 78%; left: 75%;}

    &--eco-biodechets { top: 55%; left: 67%;}
    &--eco-chaleur { top: 79%; left: 75%;}
    &--eco-batiments { top: 70%; left: 46%;}
    &--eco-mobility { top: 79%; left: 22%;}
  }
}

@media screen and (max-width: $large-screen) {
  .illustration-pointer {
    &__container {
      h3,
      a { font-size: 0.75rem;}
      &:hover,
      &:active,
      &:focus {
        &:before { width: 210px; height: 210px;}
      }
    }
  }
}

@media screen and (max-width: $small-screen) {
  .illustration-pointer {
    &__container { position:static; transform: translate(0,0);
                   display: flex; flex-direction:column; align-items: center; width: 100%; height: auto; margin-top: 30px;
                   text-align: center;
      &:before { content: none;}
      h3 { position:static; transform: translateX(0); margin-bottom: 10px; font-size: 1rem;}
      a { position:static;  transform: translate(0,0); font-size: 1rem;
        &:before,
        &:after { content: none;}
      }
    }
  }
}

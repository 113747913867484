.contact {max-width: 1000px;
  &--calculator { margin: 20px 120px; max-width: none;}
  &__fake-title { font-size: 30px; font-family: 'Montserrat', sans-serif; font-weight:200; color: $primary; text-transform: uppercase; letter-spacing: 3px;}
  &__intro { margin-bottom: 20px;}
  &__info-message { margin-bottom: 20px;
    .alert { padding: 10px;
      &-success { background-color: $primary-lightest; color: $primary; font-family: 'Montserrat', sans-serif; font-weight:700;}
      &-error { background-color: $secondary-lightest; color: $secondary; font-family: 'Montserrat', sans-serif; font-weight:700;}
    }
  }
  &__field-container { display:flex; justify-content: space-between; margin-bottom: 20px;
    .invalid-feedback { display: inline-block; width: 100%; color:$secondary; font-size: $fz--text--small; text-align: right; transform: translateY(-5px); }

  }
  &__column { width: 45%; padding: 0 10px;}
  &__footer { text-align: right; }
  input[type="text"],
  input[type="email"],
  input[type="tel"] { width: 100%; margin-bottom: 10px; padding: 5px 15px;
                      border:none; border-bottom: 1px solid $primary-darker;
                      font-family: 'Montserrat', sans-serif; font-weight:700; font-size: $fz--text;
                      &:focus { border-color: $tertiary;}
                    }
  select { width: 100%; margin-bottom: 10px; padding: 5px 15px;
           background: transparent url('../../images/front/arrow-down-primary.svg') right 10px center no-repeat; appearance:none; border: 1px solid $primary-darker;
           font-family: 'Montserrat', sans-serif; font-weight:200; font-size: $fz--text; color: $primary-darker;
           &:focus { border-color: $tertiary;}
         }
  textarea { width: 100%; margin-bottom: 10px; padding: 5px 15px;
             border:none; border-bottom: 1px solid $primary-darker;
             font-size: $fz--text;
             resize: none;
             &:focus { border-color: $tertiary;}
           }
  ::placeholder { font-family: 'Montserrat', sans-serif; font-weight:200; color: $text-color;}
}

@media screen and (max-width: $small-screen) {
  .contact {
    &--calculator { margin: 20px;}
    &__field-container {  flex-wrap: wrap;}
    &__column { width: 100%; margin-bottom: 10px;}
  }
}

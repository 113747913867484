.sitemap {
  &__accueil {text-decoration: none; color:$primary-lighter; font-family: 'Montserrat', sans-serif; font-weight:200; letter-spacing: 2px; text-transform: uppercase; font-size: 1.25rem;
    &:after { content:'';
              display: inline-block; width: 15px; height: 10px;
              padding-left: 10px;
              background: transparent url('../../images/front/arrow-right-primary.svg') center no-repeat; background-size: contain;
              transition: padding-left .3s linear;
    }
    &:hover { text-decoration: underline; cursor:pointer;
      &:after { padding-left: 15px;}
    }
  }
  li { margin-bottom: 10px;
    span, a { text-decoration: none; color:$primary-lighter; font-family: 'Montserrat', sans-serif; font-weight:200; letter-spacing: 2px; text-transform: uppercase; font-size: 1.25rem;
    }
    a {
      &:after { content:'';
                display: inline-block; width: 15px; height: 10px;
                padding-left: 10px;
                background: transparent url('../../images/front/arrow-right-primary.svg') center no-repeat; background-size: contain;
                transition: padding-left .3s linear;
      }
    }
    a:hover { text-decoration: underline; cursor:pointer;
      &:after { padding-left: 15px;}
    }
    ul { padding-left: 20px;
      li { margin-bottom: 0;
        span, a {font-family: 'Montserrat', sans-serif; font-weight:400; letter-spacing: initial; text-transform: initial; font-size: $fz--text;}
      }
    }
  }
}

.rs {
  &__icon {  }
  &--youtube {
    .rs__icon {}
  }
  &--youtube {
    .rs__icon {}
  }
}

.btn-container {
  &--left { text-align: left;}
  &--centered { text-align: center;}
  &--right { text-align: right;}
}

.btn { position: relative; z-index: 1;
       display: inline-block; padding: 12px 20px; margin: 5px 0;
       color: white; font-family: 'calibri-bold', sans-serif; text-decoration: none; border:none; font-size: 1rem;
       transition: background-color .3s linear;
       @include lhCrop(.5, 1);
       &:hover { cursor:pointer;}
  &--icon-right { padding: 13px 50px 15px 30px;
    &:after { content:''; position: absolute; top: 50%; right: 20px; transform: translateY(-50%);
      display: inline-block; width: 16px; height: 16px; margin-left: 20px;
      background: transparent url('../../images/front/arrow-right-white.svg') bottom center no-repeat; background-size: contain;
      line-height: 1;
      transition: right .2s linear;
    }
    &:hover {
      &:after { right: 15px;}
    }
  }
  &--icon-left { padding: 13px 30px 15px 50px;
    &:after { content:''; position: absolute; top: 50%; left: 20px; transform: translateY(-50%);
      display: inline-block; width: 16px; height: 16px; margin-right: 20px;
      background: transparent url('../../images/front/arrow-left-white.svg') bottom center no-repeat; background-size: contain;
      line-height: 1;
      transition: left .2s linear;
    }
    &:hover {
      &:after { left: 15px;}
    }
  }
  &--icon-no-text { padding: 0; height: 50px;width: 50px;
    &:after { content:''; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scale(1);
      display: inline-block; width: 16px; height: 16px;
      background: transparent url('../../images/front/arrow-right-white.svg') bottom center no-repeat; background-size: contain;
      line-height: 1;
      transition: transform .2s linear;
    }
    &:hover {
      &:after { transform: translate(-50%, -50%) scale(1.2);}
    }
  }
  &--animated-label {position: relative; text-align: right;
                 padding: 0;
                 line-height: 1;
    span { position: absolute; right: 0;
           display: inline-block; padding-left: 15px; padding-right: 0; width: 0;
           line-height: 50px;
           background-color: $primary-darker;
           opacity: 0;
           transition: all .2s linear;
         }
    i { position: relative;
        display: inline-block; width: 50px; height: 50px;
        background-color: $primary;
      &:before { content:'';
                 position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
                 display: inline-block; width: 16px; height: 16px;
                 background: transparent url('../../images/front/arrow-right-white.svg') bottom center no-repeat; background-size: contain;
                 line-height: 1;
      }
    }
    &:hover {
      span { padding-right: 60px; opacity: 1; width: auto;}
    }
  }
  &--alt-label { position: relative;
                 padding: 0 60px 0 15px; border: 1px solid $secondary; height: 50px; line-height: 50px;
                 text-align: right; color: $secondary;
                 background-color: transparent;
                 transition: all .2s linear;
    i { position: absolute; top: 50%; right: 0; transform: translateY(-50%);
        display: inline-block; width: 50px; height: 50px;
        background-color: $secondary;
      &:before { content:'';
                 position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%) rotate(90deg);
                 display: inline-block; width: 16px; height: 16px;
                 background: transparent url('../../images/front/arrow-right-white.svg') bottom center no-repeat; background-size: contain;
                 line-height: 1;
      }
    }
    &:hover { background-color: $secondary; color:white; }

  }
  &--primary { background-color: $primary;
    &:hover { background-color: $primary-darker; }
  }
  &--secondary { background-color: $secondary-darker;
    &:hover { background-color: $primary-darker;}
  }

  &--fullwidth { width: 100%;}

}

@media screen and (max-width: $small-screen) {
  .btn {
  }
}

.illustration { position: relative; top: 0; right: 0; z-index: -1; width: 100%; height: 100%;
  &__container { height: 100%;}
  &__img { position: fixed; top: 0; left: 0; width: 100%; height: 100%; object-fit:cover;
    &.show {z-index: 10;}
  }
  &__btn-container { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); z-index: 2;
                     width: 100%; height: 100%;
  }
  &__btn { position: absolute; transform: translate(-50%, -50%);
    &--tertiaire-creer { top: 37%; left: 38%;}
    &--tertiaire-moderniser { top: 29%; left: 55%;}
    &--tertiaire-assister { top: 42%; left: 73%;}
    &--tertiaire-adapter { top: 51%; left: 55%;}
    &--tertiaire-securiser { top: 67%; left: 66%;}
    &--tertiaire-transformer { top: 64%; left: 35%;}
    &--industrie-creer { top: 49%; left: 37%;}
    &--industrie-moderniser { top: 72%; left: 32%;}
    &--industrie-transformer { top: 60%; left: 58%;}
  }
}

.home {
  &__title { line-height: 1.1;position: relative;
             padding: 40px 0; font-family: 'clan-book';
     &:before,
     &:after { content:'';
                position: absolute; left: 0; z-index: 1;
                display: block; width: 30px; height: 6px;
                background-color: $neutral-light;
     }
     &:before { top: 0;}
     &:after { bottom: 0;}
  }
  &__logo {position: relative;
            max-width: 300px; height: auto; padding-top: 50px; margin-bottom: 40px;
    &:before { content:'';
               position: absolute; top: 0; left: 0; z-index: 1;
               display: block; width: 30px; height: 6px;
               background-color: $primary-darker;
    }
    a {display: block;
      img { width: 100%;height: auto;}
    }
  }
}

.home-intro { height: 100%; position: absolute; width: 100%; top: 0; left: 0;
  &__footer { display: flex; justify-content:flex-end; width: 100%;z-index: 10;
  }
  &__footer-spacer { flex-grow: 1; margin:20px 60px;}
  &__footer-actus-link { width: 380px; padding: 30px 30px 28px 50px;
                         display:flex; align-items:center;
                         text-decoration: none;
                         background: transparent linear-gradient(103deg, $primary-darker 0%, $primary 100%) 0% 0% no-repeat padding-box;
                         border-radius: $br-size 0 0 0;
  }
  &__actus-link-arrow { display: inline-block; width: 20px; height: 20px;
                        background:transparent url('../../images/front/arrow-right-white.svg') center no-repeat; background-size: contain;
  }
  &__actus-link-text { flex-grow: 1;
                       padding: 0 30px;
                       color:white; font-family: 'Montserrat', sans-serif; text-transform: uppercase; font-size: $fz--text--small; text-align: right; line-height: 20px;
                strong {font-weight: 700;}
                p {margin-bottom: 0;}
  }

}

.realisations-link { position: absolute; bottom: 0; right: 0; z-index: 10;
                     width: 380px; padding: 30px 30px 28px 50px;
                     display:flex; align-items:center;
                     text-decoration: none;
                     background: transparent linear-gradient(103deg, $primary-darker 0%, $primary 100%) 0% 0% no-repeat padding-box;
                     border-radius: $br-size 0 0 0;
   &__arrow { display: inline-block; width: 20px; height: 20px;
              background:transparent url('../../images/front/arrow-right-white.svg') center no-repeat; background-size: contain;
   }
   &__text { flex-grow: 1;
             padding: 0 30px;
            color:white; font-family: 'Montserrat', sans-serif; text-transform: uppercase; font-size: $fz--text--small; text-align: right; line-height: 20px;
     strong {font-weight: 700;}
     p { margin-bottom: 0;}
   }
}


@media screen and (max-width: 1200px) {
  .illustration {
    &__btn-container { display:flex; flex-direction:column; align-items: center; justify-content:center;}
    &__btn { position: static;transform: translate(0, 0);}
  }
}

@media screen and (max-width: $large-screen) {
  .illustration { position:static;transform: translate(0, 0); width: 100% !important; height: 100% !important;
    &--home { position: absolute;}
    .illustration--home & { position: absolute; height: 100%; width: 100%; object-fit: cover; top: 0; left: 0;}

    &__btn-container { display: block;}
    &__btn { position: absolute; transform: translate(-50%, -50%);
      &--tertiaire-creer { top: 37%; left: 38%;}
      &--tertiaire-moderniser { top: 29%; left: 60%;}
      &--tertiaire-assister { top: 42%; left: 73%;}
      &--tertiaire-adapter { top: 51%; left: 55%;}
      &--tertiaire-securiser { top: 67%; left: 66%;}
      &--tertiaire-transformer { top: 64%; left: 35%;}
    }
  }
}

@media screen and (max-width: $small-screen) {
  .illustration {position: absolute !important;
    &__container {min-height: 650px;}
    &__img { height: 100% !important; width: 100% !important; object-fit: cover; top: 0; left: 0;}
    &__btn-container { display:flex; flex-direction:column; align-items: center; justify-content:center;}
    &__btn { position: static;transform: translate(0, 0);}
  }

  .home-intro {
    &__footer-spacer { display: none;}
  }
  .home {
    &__logo {
      img { max-width: 200px;}
    }
  }
}

/* width */
::-webkit-scrollbar {
    width: 10px; z-index:150;
}

/* button */
::-webkit-scrollbar-button {
    background: $light-bg;display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary;
    cursor:pointer;
}

/* Track */
::-webkit-scrollbar-track {
    background: $light-bg;

}

/* The track NOT covered by the handle.
::-webkit-scrollbar-track-piece {
    background: #000;
}

/* Corner */
::-webkit-scrollbar-corner {
    background: #999;
}

/* Resizer */
::-webkit-resizer {
    background: #111;
}

.main-nav { position: absolute; top: 0; left: 0; transform: translateY(-110%); z-index: 10;
            width: 100%; min-height: 100vh; display: flex; padding: 5% 20px;
            background-color: white; box-shadow: $shadow 0 3px 6px;
            transition: transform .3s linear;

  &.open { transform: translateY(0)}

  &__toggle { position: absolute; top: 30px; right: 35px;
              display: inline-block; width: 30px; height: 30px;
              background-color: $primary-darker;
              border: none;
              &:after { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
                        content:''; display: inline-block; width: 15px; height: 15px;
                        background: transparent url('../../images/front/close.svg') center no-repeat; background-size: contain;}
              &:hover{ cursor: pointer; }
  }

  &__column { width: 30%; padding:0 60px;}

  &__link { display: inline-block;
            text-decoration: none; line-height: 1;
            @include lhCrop(.5, 1);

    &--parent { padding: 12px 20px; margin-bottom: 10px;
                background-color: $primary;
                color: white; text-transform: uppercase;
                &:hover, &:focus { background-color: $primary-darker;}
    }
    &--child { padding: 20px 10px; width: 100%;
               border-bottom: 1px solid transparentize($primary, .7);
               color:$text-color;
               &:hover, &:focus { background-color: $primary; color: white; }
    }
  }
  &__lang-switcher {display: none;}
}

.inner-nav { display: flex; justify-content: center;
  &__item { padding: 0 10px;}
  &__link { display: inline-block; padding: 12px 20px;
            background-color: $primary;
            color:white; text-decoration: none; text-transform: uppercase;
    &:hover, &:focus-within { background-color: $primary-darker;}
    &--offers { background-color: $secondary;
      &:hover, &:focus-within { background-color: $secondary-darker;}
    }
    &[aria-current="page"] { text-decoration: underline; }
  }
}

@media screen and (max-width: $xlarge-screen) {
  .inner-nav {
    &__link {
              font-size: 0.85rem;
            }
  }
}

@media screen and (max-width: $large-screen) {
  .inner-nav {
    &__link { display: inline-block; padding: 10px 15px;
              font-size: 0.75rem;
            }
  }
}


@media screen and (max-width: $small-screen) {
  .main-nav { z-index: 2; position:fixed;
              max-height: 100vh; overflow-y:scroll; flex-wrap: wrap;
    &__column { width: 100%; margin-bottom: 30px;}
    &__lang-switcher {display: block; width: 100%; text-align: center; padding: 20px;}
  }

}
